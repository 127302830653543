import { useSessionStore } from "@/stores/session";

// clicking a pcw thumbnail should open the player page
function handlePcwClick(media) {
  const { isPlusNeedPopup } = useNeedLoginPopup();
  const localePath = useLocalePath();
  if (isPlusNeedPopup(media)) return;

  if (useMediaType(media).isAShow) {
    const path = useEpisodePath({
      tvShowReference: {
        seriesExternalId: media.seriesId,
        seriesName: media.seriesName,
        seasonNumber: media.seasonNumber,
        episodeNumber: media.episodeNumber,
      },
    });

    return navigateTo(localePath(path.asString));
  }

  const query = {
    video_external_id: media.content.id,
    assetExternalId: media.content.id,
    externalContentId: media.content.id,
    type: media.type,
    contentType: media.contentType,
    externalSeriesId: media.seriesId,
    episodeNumber: media.episodeNumber,
    seasonNumber: media.seasonNumber,
    // playAt: media.viewDuration,
  };

  const sessionStore = useSessionStore();

  let path = "/watch";
  if (sessionStore.activeProfile?.isKid) {
    path = "/kids/watch";
  }

  navigateTo({
    path: localePath(path),
    query,
  });
}

function handleSportClick(media) {
  const { isPlusNeedPopup } = useNeedLoginPopup();
  const localePath = useLocalePath();

  if (isPlusNeedPopup(media)) return;
  navigateTo({
    path: localePath("/sports"),
    query: {
      channel: media.channelExternalId,
    },
  });
}

function handleCharacterClick(media) {
  const localePath = useLocalePath();
  const path = localePath(
    `/kids/characters/${media.api.query.external_entity_id}`
  );

  navigateTo(path);
}

/**
 * handles how the click event should be handled
 * when a user clicks on a thumbnail (pre-floater)
 *
 * if it's a movie/series/season,
 * open the detail modal
 *
 * if it's a PCW thumbnail,
 * go to the player page
 *
 * if it's a sports channel
 * go to the sports page
 *
 * if it's a live program that's in the future,
 * no clicks
 *
 * if it's a live program that's currently available,
 * go to the live tv player set to the channel
 */
const { openToast } = useToast();
const { useJudgeTime } = useLibs();
export default function (media, carouselType) {
  const isPcwCarouselType = carouselType == "thumbnail_with_progress";
  const isSport = media.channelExternalId?.includes("ad_sports");
  const contentTypesThatTriggerDetailModal = [
    "Movie",
    "Series",
    "Season",
    "Podcast",
  ];

  const mediaDeterminator = useMediaType(media);

  const { type } = media;
  let shouldOpenDetailPage = contentTypesThatTriggerDetailModal.includes(type);

  if (mediaDeterminator.isMajidSong) {
    shouldOpenDetailPage = false;
    usePlayClickEvent(media);
    return;
  }

  if (mediaDeterminator.isPodcast) {
    return useInfoClickEvent(media);
  }

  /**
   * new logic:
   * Open the player when episodes are part of carousels on the homepage or other landing pages.
   * For implementation, the frontend needs to check the Type "Movie" and seriesExternalId to identify episodes.
   */
  if (mediaDeterminator.isEpisode) {
    return usePlayClickEvent(media);
  }

  // if they change their mind and want to skip the detail page
  // if (type == "Movie" && contentType == "Podcast") {
  //   shouldOpenDetailPage = false;
  //   return useRadioWidgetClickEvent(media);
  // }

  if (isPcwCarouselType || mediaDeterminator.isPcwObject) {
    return handlePcwClick(media);
  }

  if (isSport) {
    return handleSportClick(media);
  }

  if (mediaDeterminator.isLiveProgram) {
    const { inValid, content } = useJudgeTime(media);
    if (inValid) {
      return openToast({ content, type: "loading" });
    } else {
      return useInfoClickEvent(media, { replace: true });
    }
  }

  if (shouldOpenDetailPage) {
    return useInfoClickEvent(media, { replace: true });
  }

  if (mediaDeterminator.isCharacter) {
    return handleCharacterClick(media);
  }

  media.playButtonConfig?.onClick.func(...media.playButtonConfig?.onClick.args);
}
